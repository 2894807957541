import { Component, Input } from '@angular/core';
import { ImageService } from './../../services/image.service';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input('user') user: any;

  constructor(
    private readonly imageService: ImageService,
    private readonly storage: StorageService
  ) {}

  ngOnInit() {
    if (this.storage.has('avatar')) {
      const avatar = this.storage.get('avatar');
      console.log(avatar);
    } else {
      this.imageService.user().subscribe((response) => {
        const avatar = response.data.image;
        if (this.user && avatar) {
          this.user['avatar'] = 'data:image/png;base64,' + avatar;
        }
      });
    }
  }
}
